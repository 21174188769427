.current-car {

  // z-index: 999;
  // position: fixed;
  // bottom: 12*$unitMult+$unit;
  // background: #a6ca9a;

  background: #fbfbfb;
  border-bottom: solid 1px $primary;


  align-items: center;
  // right: 1*$unitMult+$unit;
  display: flex;
  padding: 1*$unitMult+$unit 4*$unitMult+$unit ;
  box-shadow: 0 0 3*$unitMult+$unit rgba(0,0,0,.2);



  .reset-vehicle {
    svg {
      width: 4*$unitMult+$unit;
      margin-right: 1*$unitMult+$unit;

    }
  }

  h3 {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 2*$unitMult+$unit !important;
  }
}



.select-vehicle>div{
  display: flex;
  align-items: center;
  border: solid 1px #ccc;
  background-color: #fbfbfb;
  padding: 2*$unitMult+$unit;
  margin-bottom: 3*$unitMult+$unit;
  border-radius: $unitMult+$unit;

  .vehicle {
    flex: 1;
  }
  button {
    font-weight: bold;
    margin-left: 4*$unitMult+$unit;
  }
}


.current-car, .select-vehicle {



  .vehicle {
    display: flex;
    >div {
      margin-left: 1rem;

    }
  }


  .regno {

    .country {
      background-color: #0b65c1;
      padding: 0.5*$unitMult+$unit;
      .inner {

        width: 2*$unitMult+$unit;
        height: 100%;

        background-image: url('../images/vehicle/license_plate_0003_se.png');
        background-size: contain;
        background-repeat: no-repeat;

      }

    }


    .text {
      padding: 0 .7*$unitMult+$unit;
    }

    border: solid 1px #ccc;
    background: #ffff;
    display: flex;
    overflow: hidden;
    border-radius: 1*$unitMult+$unit;

  }


}
