
body, h1, h2, h3, h4, h5, h6 {
  font-family: 'Brown Pro', 'Source Sans Pro', sans-serif;
}


.mode-app, .mode-web-kiosk {
  .start {
    .banner {
      background: url('../../images/client/banner.jpg');
      background-size: cover;
      background-position: center;
    }
  }
}
