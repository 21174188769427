
@font-face {
  font-family: 'Brown Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/BrownPro/BrownPro-Regular.ttf');
}

@font-face {
  font-family: 'Brown Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/BrownPro/BrownPro-Bold.ttf');
}
